import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Gallery/Gallery.css';
import indoorImage1 from '../Images/Indoor Amenities Renders/Gym - new.jpg';
import indoorImage3 from '../Images/Indoor Amenities Renders/Indoor play area.jpg';
import indoorImage2 from '../Images/Indoor Amenities Renders/Toddlers room -new.jpg';
import bardeck from '../Images/Rooftop Amenities Renders/BAR DECK.jpg';
import rooftopImage1 from '../Images/Rooftop Amenities Renders/CABANA SEATING.jpg';
import rooftopImage2 from '../Images/Rooftop Amenities Renders/PARTY LAWN.jpg';
import rooftopImage3 from '../Images/Rooftop Amenities Renders/STAR GAZING DECK -NEW 1.jpg';

const Gallery = () => {
  const [activeSection, setActiveSection] = useState('rooftop');

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const renderImageSlides = (section) => {
    const images = section === 'rooftop' 
      ? [rooftopImage1, rooftopImage2, rooftopImage3, bardeck, rooftopImage1, rooftopImage2]
      : [indoorImage1, indoorImage2, indoorImage3, bardeck, indoorImage1, indoorImage2];

    const groupedImages = [];
    for (let i = 0; i < images.length; i += 3) {
      groupedImages.push(
        <div key={i} className="image-group">
          {images.slice(i, i + 3).map((image, index) => (
            <div key={index} className="image-slide">
              <img src={image} alt={`Image ${i + index + 1}`} />
            </div>
          ))}
        </div>
      );
    }

    return groupedImages;
  };

  return (
    <section className="gallery">
      <h2 className="gallery-heading">Gallery</h2>
      <div className="section-buttons">
        <button className={`section-button ${activeSection === 'rooftop' ? 'active' : ''}`} onClick={() => handleSectionChange('rooftop')}>
          Rooftop
        </button>
        <button className={`section-button ${activeSection === 'indoor' ? 'active' : ''}`} onClick={() => handleSectionChange('indoor')}>
          Indoor
        </button>
      </div>
      <div className="image-slider">
        <Carousel showThumbs={false} showStatus={false} infiniteLoop showArrows={true} showIndicators={false}>
          {renderImageSlides(activeSection)}
        </Carousel>
      </div>
    </section>
  );
};

export default Gallery;
