import React from 'react';
import f1 from '../Images/floor_plan/f1.jpeg';
import f2 from '../Images/floor_plan/f2.jpeg';
import f3 from '../Images/floor_plan/f3.jpeg';
import f4 from '../Images/floor_plan/f4.jpeg';
import f5 from '../Images/floor_plan/f5.jpeg';
import f6 from '../Images/floor_plan/f6.jpeg';
import f7 from '../Images/floor_plan/f7.jpeg';
import f8 from '../Images/floor_plan/f8.jpeg';
import './FloorPlan.css';

const floorPlans = [
  { id: 1, imgSrc: f1, altText: 'Floor Plan 1' },
  { id: 2, imgSrc: f2, altText: 'Floor Plan 2' },
  { id: 3, imgSrc: f3, altText: 'Floor Plan 3' },
  { id: 4, imgSrc: f4, altText: 'Floor Plan 4' },
  { id: 5, imgSrc: f5, altText: 'Floor Plan 5' },
  { id: 6, imgSrc: f6, altText: 'Floor Plan 6' },
  { id: 7, imgSrc: f7, altText: 'Floor Plan 7' },
  { id: 8, imgSrc: f8, altText: 'Floor Plan 8' },
];

const FloorPlan = ({ onOpenDialog }) => {

  return (
    <div className="floor-plan-container">
      <div className="master-plan">
        <img
          src="masterplan.jpg" // Replace with actual master plan image source
          alt="Master Plan"
          className="master-plan-image"
        />
        <button className="view-master-plan-button">VIEW MASTER PLAN</button>
      </div>
      <h3>Floor Plan</h3>
      <div className="floor-plans">
        {floorPlans.map((plan) => (
          <div key={plan.id} className="floor-plan-item">
            <div className="floor-plan-item-image">
              <img
                src={plan.imgSrc}
                alt={plan.altText}
                className="floor-plan-image"
                onClick={onOpenDialog}
              />
            </div>
            <button
              className="floor-plan-button"
              onClick={onOpenDialog}
            >
              Floor Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FloorPlan;
