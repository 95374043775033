import React from "react";
import { FaTimes } from "react-icons/fa"; // Import the close icon
import "./Dialog.css"; // Ensure the correct path to your CSS file
import Dialogform from "./dialogform";

const Dialog = ({ onClose }) => {
  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <div style={{ display: "flex", flexDirection: "row",borderTopRightRadius:'5px',borderTopLeftRadius:'5px', justifyContent:'space-between' , alignItems:'center',background:'#8ac5f6', padding:'10px' }}>
          <p style={{fontSize:'20px', fontWeight:'bolder', marginRight:'50px'}}>Send Me Plan Details</p>
          <div className="close-button" onClick={onClose}>
            <FaTimes />
          </div>
        </div>
        <div className="contact-form-container">
          <Dialogform />
        </div>
      </div>
    </div>
  );
};

export default Dialog;
