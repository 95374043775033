import React from 'react';
import './BookingOpen.css';

const BookingOpen = ({ onOpenDialog }) => {
  return (
    <div className="booking-open">
      <h1>BOOKING OPEN</h1>
      <h2>BHOOMI CASTLE SHILPHATA</h2>
      <p>At Shilphata Road, Thane</p>
      <p>By Gajra Bhoomi</p>
      <div className="details">
      <div className='details-item-border'>
        <div className="details-item">Land Parcel - 3.5 Acre</div>
        <div className="details-item">Total Towers - 4</div>
        <div className="details-item">Free Site Visit.</div>
        <div className="details-item">Avail Spot Booking Offer*.</div>
        <div className="details-item">Early Choice Of Inventory.</div>
        <div className="details-item">Exclusive Discount.</div>
        </div>
      </div>
      <h3>1 & 2 BHK Spacious Homes</h3>
      <h4>Starting ₹ 41 Lakhs* Onwards</h4>
      <button className="enquire-button"  onClick={onOpenDialog}>Enquire Now</button>
    </div>
  );
}

export default BookingOpen;
