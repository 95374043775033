import React from 'react';
import imageSrc from '../Images/map.jpeg'; // Replace with the correct path to your image
import './VirtualTour.css';

const VirtualTour = () => {
  return (
    <div style={{backgroundColor:'cadetblue'}}>
    <h1 style={{textAlign:'center'}}>Virtual Tour</h1>
    <div className="virtual-tour-section">
      <div className="image-container">
        <img src={imageSrc} alt="Descriptive Alt Text" />
      </div>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/VAtihVsQ7Rg?si=aXYLZkt422nXnF5l"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    </div>
  );
};

export default VirtualTour;
