import React, { useState } from 'react';
import '../Footer/Footer.css';
import Modal from '../Modal/Modal';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
const Footer = () => {
  const[showPrivacyPolicy,setShowPrivacyPolicy] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Bhoomi Castle. All Rights Reserved.</p>
        {console.log(showPrivacyPolicy)}
       <Modal  isOpen={isModalOpen} onClose={closeModal}><PrivacyPolicy/></Modal> 
        
        <div className="footer-links">
          <a href="#Privacy-Policy" onClick={()=> setIsModalOpen(true)}>Privacy Policy</a>
          <a href="#configuration">Configuration</a>
          <a href="#amenities">Amenities</a>
          <a href="#connectivity">Connectivity</a>
          <a href="#gallery">Gallery</a>
          <a href="#location">Location</a>
          <a href="#about">About</a>
        </div>


        <div className="footer-links-mobile">
        <div className="footer-link-group">
            <a href="#Privacy-Policy">Privacy Policy</a>
            <a href="#configuration">Configuration</a>
            <a href="#amenities">Amenities</a>
          </div>
          <div className="footer-link-group">
            <a href="#connectivity">Connectivity</a>
            <a href="#gallery">Gallery</a>
            <a href="#location">Location</a>
          </div>
          <div className="footer-link-group">
            <a href="#about">About</a>
            <a href="#contact">Contact</a>
            {/* <a href="#privacy">Privacy Policy</a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
