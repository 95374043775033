import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BookingOpen from '../BookingOpen/BookingOpen';
import '../Carousel/Carousel.css'; // Custom styles for the carousel
import Dialog from '../Dialog/Dialog';
import partlawn from '../Images/Rooftop Amenities Renders/PARTY LAWN.jpg';
import petpark from '../Images/Rooftop Amenities Renders/PET PARK.jpg';
import walkway from '../Images/Rooftop Amenities Renders/WALK WAY AROUND.jpg';
import Spinner from '../Spinner/Spinner'; // Import a spinner component

const ImageCarousel = () => {
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const carouselRef = useRef(null);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const images = [walkway, petpark, partlawn];
    let loadedImages = 0;

    images.forEach(src => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setLoading(false);
        }
      };
    });

    // Measure carousel height after it has loaded
    if (carouselRef.current) {
      setCarouselHeight(carouselRef.current.offsetHeight);
    }
  }, []);

  return (
    <div className="carousel-booking-container">
      {loading ? (
        <Spinner />
      ) : (
        <div className="carousel-booking-content">
          <Carousel 
            ref={carouselRef} 
            showArrows={true} 
            autoPlay={true} 
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            interval={2000}
            transitionTime={500}
          >
            <div>
              <img src={walkway} alt="Walkway" />
            </div>
            <div>
              <img src={petpark} alt="Pet Park" />
            </div>
            <div>
              <img src={partlawn} alt="Party Lawn" />
            </div>
          </Carousel>
          <div className='bookingopensection' style={{ height: carouselHeight }}>
            <BookingOpen onOpenDialog={openDialog}/>
          </div>
        </div>
      )}
      {showDialog && <Dialog onClose={closeDialog}/>}
    </div>
  );
};

export default ImageCarousel;
