import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto',color: "#000" }}>
      <h1>Privacy Policy</h1>
      <p>
        Protecting your privacy is important to us & this Privacy Policy outlines how we collect, use, disclose, and manage your personal information.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We may collect personal information such as your name, email address, phone number, and property preferences. We also collect information about your interactions with us, including website usage data and communications.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We use your information to provide and improve our services, communicate with you, personalize your experience, and comply with legal obligations. We may also use your information for marketing purposes with your consent.
      </p>
      <h2>Information Sharing</h2>
      <p>
        We may share your information with third parties, including service providers and legal authorities, as required by law or to protect our rights. We do not sell your information to third parties.
      </p>
      <h2>Your Privacy Choices</h2>
      <p>
        You have the right to access, correct, or delete your personal information. You can also choose to opt out of marketing communications. To exercise these rights, please contact us using the information below.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy, or if you would like to exercise your privacy rights, please contact us at:
      </p>
      <p>
        Bhoomi Castle<br />
        Mobile: +91 99209-2558<br />
        Email: <a href="mailto:enquiry@kamdhenu.com">enquiry@kamdhenu.com</a>
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this Policy regularly. Your continued use of our services indicates your acceptance of the updated Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
