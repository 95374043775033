import React from 'react';
import '../Contact/Contact.css';
import ContactForm from '../ContactForm/ContactForm';
import MapContainer from '../MapContainer/MapContainer';

const Contact = () => {
  return (
    <section className="contact">
      <div className="contact-section">
        <MapContainer />
      </div>
      <div className="contact-section with-background">
        <h2>Contact Us</h2>
        <ContactForm />
      </div>
    </section>
  );
};

export default Contact;
