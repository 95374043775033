// AboutUs.js
import React from 'react';
import '../About/Aboutus.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Gajra</h2>
      <p>
        With a wealth of experience in the industry, we stand as a trusted name synonymous with quality and commitment. Our journey is rooted in a dedication to delivering unparalleled experiences to our clients. As a team, we take pride in our proven track record, earning the trust of those we serve. Our commitment to quality is unwavering, and it is this commitment that drives us to exceed expectations. Discover a partner in us who values your experience, upholds a trusted name, and consistently delivers quality through unwavering commitment. Welcome to a space where your expectations are not just met but exceeded.
      </p>
      <div className="stats">
        <div className="stat-item">
          <strong>20+ Years Of Inspiring Life</strong>
        </div>
        <div className="stat-item">
          <strong>65+ Projects</strong>
        </div>
        <div className="stat-item">
          <strong>5000+ Happy Customers</strong>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
