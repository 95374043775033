import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import '../Dialog/dialogform.css';

const Dialogform = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    } else {
      newErrors.name = '';
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone.trim())) {
      newErrors.phone = 'Phone number must be 10 digits';
      valid = false;
    } else {
      newErrors.phone = '';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.trim())) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } else {
      newErrors.email = '';
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phone' && value.length > 10) {
      // Limit phone number input to 10 characters
      return;
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch('https://bhoomicastlegajraapi.kamdhenu-thehallmark-minespacerealty.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      console.log('API Response:', data);

      // Display response message
      alert(data.message);

      // Reset form data after successful submission
      setFormData({ name: '', phone: '', email: '' });
      setErrors({ name: '', phone: '', email: '' });
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <form className="contact-form2" onSubmit={handleSubmit}>
      <h4>Register Here And Avail The Best Offers!!</h4>
      <div className="input-container2">
        <div className="input-group">
          <FontAwesomeIcon icon={faUser} className="icon" />
          <input
            type="text"
            placeholder="Name"
            id="name"
            name="name"
            className="input-field2"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="input-group">
          <FontAwesomeIcon icon={faPhone} className="icon" />
          <input
            type="tel"
            placeholder="Phone"
            id="phone"
            name="phone"
            className="input-field"
            value={formData.phone}
            onChange={handleChange}
            maxLength={10} // Limit input length to 10 characters
          />
          {errors.phone && <span className="error-message">{errors.phone}</span>}
        </div>
        <div className="input-group">
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <input
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            className="input-field"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>
      </div>
      <button type="submit" className="submit-button">Submit</button>
    </form>
  );
};

export default Dialogform;
