import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import gajra from '../Images/gajra.jpg';
import logo from '../Images/logo.jpeg';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="left-logo">
        <img src={logo} alt="Left Logo" />
      </div>
      <nav className={`menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#carousel" onClick={handleMenuItemClick}>Home</a></li>
          <hr className="menu-divider" />
          <li><a href="#masterpiece" onClick={handleMenuItemClick}>Masterpiece</a></li>
          <hr className="menu-divider" />
          <li><a href="#configuration" onClick={handleMenuItemClick}>Configuration</a></li>
          <hr className="menu-divider" />
          <li><a href="#gallery" onClick={handleMenuItemClick}>Gallery</a></li>
          <hr className="menu-divider" />
          <li><a href="#virtual-tour" onClick={handleMenuItemClick}>Virtual Tour</a></li>
          <hr className="menu-divider" />
          <li><a href="#floor-plan" onClick={handleMenuItemClick}>Floor Plan</a></li>
          <hr className="menu-divider" />
          <li><a href="#about" onClick={handleMenuItemClick}>About</a></li>
          <hr className="menu-divider" />
          <li><a href="#contact" onClick={handleMenuItemClick}>Contact</a></li>
        </ul>
      </nav>
      <div className="right-logo">
        <img src={gajra} alt="Right Logo" />
      </div>
      <div className="menu-toggle-container">
        <img src={gajra} alt="Right Logo" className="right-logo-small" />
        <div className="menu-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
};

export default Header;
