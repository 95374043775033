import React, { useState } from 'react';
import BlinkingText from '../BlinkingText/BlinkingText';
import '../ProjectConfiguration/ProjectConfiguration.css';

const ProjectConfiguration = ({onOpenDialog}) => {
  const [activeTower, setActiveTower] = useState('Tower 1 Highness');

  const handleTowerChange = (tower) => {
    setActiveTower(tower);
  };

  return (
    <section className="project-configuration">
      <div className="project-configuration-container">
        <h2 className="project-configuration-title">Project Configuration</h2>
        <div className="tower-buttons">
          <button
            className={`tower-button ${activeTower === 'Tower 1 Highness' ? 'active' : ''}`}
            onClick={() => handleTowerChange('Tower 1 Highness')}
          >
            Tower 1 Highness
          </button>
          <button
            className={`tower-button ${activeTower === 'Tower 2 Monarch' ? 'active' : ''}`}
            onClick={() => handleTowerChange('Tower 2 Monarch')}
          >
            Tower 2 Monarch
          </button>
        </div>

        {/* <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
          <p style={{ textAlign:'center', width:'fitContent', color:'white', background:'red', padding:'10px', borderRadius:'5px'}}>Starts from 41 lacs</p>
        </div> */}

        <BlinkingText/>
        <div className="table-container">
          <table className="configuration-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Rera Carpet Area</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {activeTower === 'Tower 1 Highness' && (
                <>
                  <tr>
                    <td>1 BHK</td>
                    <td>449 sq.ft. - 450 sq.ft.</td>
                    <td><button onClick={onOpenDialog}>Click Here</button></td> 
                  </tr>
                  <tr>
                    <td>2 BHK</td>
                    <td>625 sq.ft. - 723 sq.ft.</td>
                    <td><button onClick={onOpenDialog}>Click Here</button></td> 
                  </tr>
                </>
              )}
              {activeTower === 'Tower 2 Monarch' && (
                <>
                  <tr>
                    <td>1 BHK</td>
                    <td>452 sq.ft.</td>
                    <td><button onClick={onOpenDialog}>Click Here</button></td> 
                  </tr>
                  <tr>
                    <td>2 BHK</td>
                    <td>623 sq.ft.</td>
                    <td><button onClick={onOpenDialog}>Click Here</button></td> 
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ProjectConfiguration;
