import React from 'react';
import './BlinkingText.css'; // Import the CSS file with the animations

const BlinkingText = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <p
        style={{
          textAlign: 'center',
          width: 'fit-content',
          color: 'white',
          background: 'red',
          padding: '10px',
          borderRadius: '5px'
        }}
        className="blinking scale-pulse"
      >
        Starts from 41 lacs
      </p>
    </div>
  );
};

export default BlinkingText;
