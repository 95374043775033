import React from 'react';
import masterpieceImage from '../Images/building.jpeg'; // Make sure to replace this with the correct path
import '../Masterpiece/Masterpiece.css';

const Masterpiece = () => {
  return (
    <section className="masterpiece">
    <div className="masterpiece-heading">
    <h1 className="masterpiece-title">A Grand Masterpiece in the Making</h1>
    <div className="animated-line-container">
      <div className="animated-line"></div>
    </div>
    </div>
      <div className="masterpiece-container">
        <div className="masterpiece-text">
          <p className="masterpiece-description">
            Set amidst a tranquil scenery, Bhoomi Castle seamlessly intertwines modern luxury,
            delivering a splendid living experience for individuals in pursuit of majestic opulence.
            Grandness is easy access to luxury. Grandness is connectivity that takes you places.
            Grandness is a community of like-minded people. Grandness is a living space that's
            not just about living, but living the extraordinary life.
          </p>
        </div>
        <div className="masterpiece-image">
          <img src={masterpieceImage} alt="Bhoomi Castle" />
        </div>
      </div>
    </section>
  );
};

export default Masterpiece;
